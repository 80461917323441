import React from 'react';
import styles from './SuggestiesLarge.module.css';
import classNames from 'classnames';

const SuggestiesLarge = ({className}) => {
    return (
        <div className={classNames(styles.suggesties, className)}>
            <span>SUGGESTIES: ZIE BORD</span>
        </div>
    );
};

export default SuggestiesLarge;
