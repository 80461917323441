import React from 'react';
import styles from './GlasLogo.module.css';
import logo from "../../images/logo.jpg";
import classNames from 'classnames';
import {Link} from "react-router-dom";

const GlasLogo = ({className}) => {
    return (
        <div className={classNames(styles.wrapper, className)}>
            <div className={styles.logoRow}>
                <Link to="/">
                    <img alt="" className={styles.logo} src={logo}/>
                </Link>
            </div>

            <div className={styles.name}>VOLKSSTAMENEI!</div>
        </div>
    );
};

export default GlasLogo;
