import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import FooterLogo from "../components/FooterLogo/FooterLogo";
import MenuSection from "./MenuSection/MenuSection";
import Suggesties from "./Suggesties/Suggesties";
import Zaaltjes from "./Zaaltjes/Zaaltjes";
import GlasLogo from "../components/GlasLogo/GlasLogo";
import styles from "./Menu.module.css";
import fbLogo from "../images/facebook-7-64.png";
import SuggestiesLarge from "./SuggestiesLarge/SuggestiesLarge";

const Menu = () => {
    const menu = [
        {
            title: 'BIEREN VAN T VAT',
            items: [
                {title: 'Stella', description: '', price: '2,90/3,10'},
                {title: 'Hoegaarden', description: '', price: '3,00'},
                {title: 'Bier van het moment', description: '', price: ''},
            ],
        },
        {
            title: 'BIEREN OP FLES',
            items: [
                {title: 'Stella 0,0%', description: '25cl', price: '3,10'},
                {title: 'Sportzot', description: 'alcoholvrij (0,5%) 33cl', price: '4,50'},
                {title: 'Vanderghinste', description: 'roodbruin 25cl', price: '3,90'},
                {title: 'Kriek / Geuze', description: 'Boon 25cl', price: '3,90'},
                {title: 'Omer', description: '33cl', price: '4,50'},
                {title: 'Saison Dupont', description: '33cl', price: '4,50'},
                {title: 'Duvel', description: '33cl', price: '4,50'},
                {title: 'Duvel Tripel Hop', description: '33cl', price: '5,10'},
                {title: 'Tripel Karmeliet', description: '33cl', price: '4,40'},
                {title: 'Cornet', description: '33cl', price: '4,50'},
                {title: 'Delvaux', description: '33cl', price: '4,50'},
                {title: 'La Chouffe', description: '33cl', price: '4,50'},
                {title: 'Kasteel Rouge', description: '33cl', price: '4,80'},
                {title: 'Gouden Carolus Tripel', description: '33cl', price: '4,80'},
                {title: 'Gouden Carolus Classic', description: '33cl', price: '5,00'},
                {title: 'Cidre Ruwet', description: '33cl', price: '5,20'},
                {title: 'Zinnebir', description: '33cl', price: '5,40'},
                {title: 'Pico Bello', description: 'alcoholvrij (0,3%) 33cl', price: '5,50'},
                {title: 'Delta IPA', description: '33cl', price: '5,20'},
                {title: 'Jungle Joy', description: '33cl', price: '5,30'},
                {title: 'Papegaei', description: '33cl', price: '5,00'},
                {title: 'Ouwen Duiker', description: '33cl', price: '5,00'},
            ],
        },
        {
            title: 'PROMO',
            items: [
                {title: 'Pink Gin&Tonic', description: '', price: '8,00'},
            ],
        },
        {
            title: 'TRAPPIST',
            items: [
                {title: 'Westmalle dubbel / trippel', description: '', price: '4,80'},
                {title: 'Chimay wit / blauw', description: '', price: '4,80'},
                {title: 'La Trappe quadrupel', description: '', price: '5,10'},
                {title: 'Orval', description: '', price: '5,10'},
            ],
        },
        {
            title: 'OM WEL/NIET TE DELEN',
            items: [
                {title: 'Lambiek Fabriek Oude Kriek', description: '75cl', price: '20,00'},
                {title: 'Lambiek Fabriek Oude Geuze', description: '37,5cl', price: '10,00'},
                {title: 'Tilquin A L\'Ancienne', description: '75cl', price: '22,00'},
                {title: 'Tilquin Pinot Noir', description: '75cl', price: '30,00'},
                {title: 'Tilquin Roussanne', description: '75cl', price: '31,50'},
                {title: '3 Fonteinen Schaarbeekse Kriek', description: '75cl', price: '36,00'},
                {title: '3 Fonteinen Gele Perzik', description: '75cl', price: '35,00'},
            ],
        },
        {
            title: 'WIJNEN',
            items: [
                {title: 'Wit', description: 'Sauvignon glas/fles', price: '4,50/24'},
                {title: 'Rosé', description: 'Syrah/Rosado glas/fles', price: '4,50/24'},
                {title: 'Rood', description: 'Tempranillo Tinto glas/fles', price: '4,50/24'},
                {title: 'Cava', description: 'glas/fles', price: '6/26'},
            ],
        },
        {
            title: 'FRIS',
            items: [
                {title: 'Pepsi cola/max', description: '', price: '2,80'},
                {title: 'Spa Reine / Intense', description: '', price: '2,80'},
                {title: 'Mirinda', description: 'gele limonade', price: '2,80'},
                {title: '7Up', description: '', price: '2,80'},
                {title: 'Looza', description: 'appel / sinaas / pompel / tomaat', price: '2,90'},
                {title: 'Almdudler', description: '', price: '2,80'},
                {title: 'Schweppes', description: 'Tonic / Agrum / Bitter Lemon / Ginger Ale', price: '3,00'},
                {title: 'Oasis', description: '', price: '3,00'},
                {title: 'Ijsthee', description: 'v/h huis rozenbottel / gember', price: '2,80'},
                {title: 'Choco Inza', description: '', price: '2,70'},
                {title: 'Fever Tree', description: 'indian / ginger beer', price: '3,50'},
                {title: 'Pimento', description: '', price: '3,50'},
                {title: 'Verse sinaas / citroen', description: '', price: '5,50'},
            ],
        },
        {
            title: 'WARM',
            items: [
                {title: 'Koffie', description: '', price: '2,50'},
                {title: 'Deca', description: '', price: '2,60'},
                {title: 'Espresso', description: '', price: '2,50'},
                {title: 'Espresso', description: 'Karamel / Vanille / Chocolat', price: '2,80'},
                {title: 'Cappuccino', description: '', price: '3,00'},
                {title: 'Koffie verkeerd', description: '', price: '3,00'},
                {title: 'Warme Choco', description: '', price: '2,80'},
                {title: 'Irish coffee', description: '(whisky)', price: '8,00'},
            ],
        },
        {
            title: 'THEE',
            items: [
                {title: 'Groen / zwart / rozenb. / munt / kamille', description: '', price: '2,70'},
                {title: 'Gimber gemberthee', description: '', price: '3,50'},
            ],
        },
        {
            title: 'ZOETIGHEDEN',
            items: [
                {title: 'Cake / taart', description: '', price: '5,00'},
                {title: 'Frisco, Raket of Cornet', description: '', price: '1,50'},
            ],
        },
        {
            title: 'APERITIEF',
            items: [
                {title: 'Pastis Ricard', description: '', price: '3,00'},
                {title: 'Crodino', description: 'alcoholvrij', price: '3,00'},
                {title: 'Campari', description: '', price: '4,50'},
                {title: 'Cynar', description: '', price: '4,50'},
                {title: 'Gancia', description: '', price: '4,00'},
                {title: 'Picon', description: '', price: '3,80'},
                {title: 'Porto', description: 'wit / rood', price: '4,00'},
                {title: 'Sherry', description: '', price: '4,20'},
                {title: 'Pineau de Charentes', description: '', price: '4,30'},
                {title: 'Lillet', description: 'wit / rood / rosé', price: '5,00'},
                {title: 'Half om Half', description: '(wijn-cava)', price: '5,00'},
                {title: 'Vermouth', description: '', price: '5,00'},
                {title: 'Aperol Spritz', description: '', price: '8,50'},
            ],
        },
        {
            title: 'ALCOHOL / DIGESTIEF',
            items: [
                {title: 'Wodka Absolut', description: '', price: '6,50'},
                {title: 'Gin Ceder\'s Crisp', description: 'alcoholvrij', price: '5,50'},
                {title: 'Beefeater', description: '', price: '6,50', indent: 34},
                {title: 'Copperhead', description: '', price: '9,00', indent: 34},
                {title: 'Monkey 47', description: '', price: '8,00', indent: 34},
                {title: 'Rum Havana Club 3y', description: '', price: '5,50'},
                {title: 'Havana Especial', description: '', price: '6,50', indent: 39},
                {title: 'Havana Club Anejo 7', description: '', price: '7,50', indent: 39},
                {title: 'Diplomatico', description: '', price: '8,00', indent: 39},
                {title: 'Whisky J&B', description: '', price: '5,00'},
                {title: 'Jameson', description: '', price: '5,50', indent: 64},
                {title: 'Four Roses', description: '', price: '6,00', indent: 64},
                {title: 'Jack Daniels', description: '', price: '6,50', indent: 64},
                {title: 'Gouden Carolus', description: '', price: '9,50', indent: 64},
                {title: 'Macallan 12y', description: '', price: '10,50', indent: 64},
                {title: 'Southern Comfort', description: '', price: '6,00'},
                {title: 'Tequila Olmeca', description: '', price: '5,00'},
                {title: 'Jenever', description: 'jonge / oude / appel', price: '4,50'},
                {title: 'Cointreau', description: '', price: '5,50'},
                {title: 'Limoncello', description: '', price: '5,50'},
                {title: 'Amaretto', description: '', price: '6,00'},
                {title: 'Baileys', description: '', price: '5,50'},
                {title: 'Amaro', description: '', price: '5,00'},
                {title: 'Sambuca', description: '', price: '5,50'},
                {title: 'Cognac VSOP', description: '', price: '7,50'},
                {title: 'Calvados', description: '', price: '6,00'},
                {title: 'Grappa', description: '', price: '7,00'},
            ],
        },
        {
            title: '+ TOESLAG LONGDRINK',
            items: [
                {title: 'Frisdrank', description: '', price: '2,70'},
                {title: 'Fever Tree / Pimento', description: '', price: '3,00'},
                {title: 'Wijn', description: '', price: '3,00'},
                {title: 'Cava', description: '', price: '4,00'},
            ],
        },
        {
            title: 'COCKTAIL LVNCNTRL',
            items: [
                {title: 'Gember limo / Gin / Lillet', description: '', price: '7,00'},
                {title: 'Rozenbottel limo / Wodka / Lillet', description: '', price: '7,00'},
            ],
        },
        {
            title: 'PISTOLETS',
            items: [
                {title: 'Ene me hesp of ene me kees', description: '', price: '2,70'},
                {title: 'kip me curry of krabsaloot', description: '', price: '2,70'},
                {title: 'paté of fillet Américain', description: '', price: '2,70'},
                {title: 'Pistolei me boulet & tartaar', description: '', price: '3,50'},
            ],
        },
        {
            title: 'CROQUE MONSIEUR',
            items: [
                {title: 'Handgesneden brood kaas & hesp', description: '', price: '6,50'},
            ],
        },
        {
            title: 'CENTRAL KLASSIEKERS',
            items: [
                {title: 'Spaghetti', description: 'Bolo: klein / groot', price: '11/14'},
                {title: 'Pasta', description: 'Puttanesca: klein / groot', price: '10,50/13,50'},
                {title: '', description: 'Extra saus / extra kaas / extra brood', price: '1,00'},
                {title: 'Veggie Curry', description: '', price: '11,00'},
                {title: 'Chili con Carne', description: 'met brood, zure room en kaas', price: '9,00'},
                {title: 'Kippenballetjes', description: 'gele curry, prei en wortel', price: '14,50'},
                {title: 'Houmous', description: 'met turks brood', price: '6,50'},
            ],
        },
        {
            title: 'SOEPKES',
            items: [
                {title: 'Verse dagsoep', description: '(met brood)', price: '6,00'},
                {title: 'Escargots', description: '(met pittige soep)', price: '4,50'},
            ],
        },
        {
            title: 'FIJNE VLEESWAREN',
            items: [
                {title: 'Cervela', description: '', price: '4,00'},
                {title: 'Pens', description: 'wit of zwart', price: '3,80'},
                {title: 'Droge worst', description: '', price: '3,00'},
                {title: 'Pikante worst', description: '', price: '4,00'},
                {title: 'Huisgemaakte bouletten', description: 'met verse tartaar', price: '6,00'},
            ],
        },
        {
            title: 'KNABBELEN',
            items: [
                {title: 'Chips', description: 'zout, paprika, pickles, superchips of grills', price: '1,50'},
                {title: 'Nootjes', description: '', price: '1,50'},
                {title: 'Portie Olijven', description: '', price: '3,50'},
                {title: 'Portie Kaas', description: '', price: '4,50'},
                {title: 'Edamame', description: '', price: '5,00'},
            ],
        },
    ];


    return (
        <Container>
            <GlasLogo/>
            <Row style={{marginTop: 30}}>
                <Col xs={12} md={6} xl={3}>
                    <MenuSection section={menu.find(section => section.title === 'BIEREN VAN T VAT')}/>
                    <MenuSection section={menu.find(section => section.title === 'BIEREN OP FLES')}/>
                    <MenuSection section={menu.find(section => section.title === 'PROMO')}/>
                    <Suggesties/>
                    <MenuSection section={menu.find(section => section.title === 'TRAPPIST')}/>
                    <MenuSection section={menu.find(section => section.title === 'OM WEL/NIET TE DELEN')}/>
                    <MenuSection section={menu.find(section => section.title === 'WIJNEN')}/>
                </Col>
                <Col xs={12} md={6} xl={3}>
                    <MenuSection section={menu.find(section => section.title === 'FRIS')}/>
                    <MenuSection section={menu.find(section => section.title === 'WARM')}/>
                    <MenuSection section={menu.find(section => section.title === 'THEE')}/>
                    <MenuSection section={menu.find(section => section.title === 'ZOETIGHEDEN')}/>
                    <MenuSection section={menu.find(section => section.title === 'APERITIEF')}/>
                </Col>
                <Col xs={12} md={6} xl={3}>
                    <MenuSection section={menu.find(section => section.title === 'ALCOHOL / DIGESTIEF')}/>
                    <MenuSection section={menu.find(section => section.title === '+ TOESLAG LONGDRINK')}/>
                    <MenuSection section={menu.find(section => section.title === 'COCKTAIL LVNCNTRL')}/>
                </Col>
                <Col xs={12} md={6} xl={3}>
                    <MenuSection section={menu.find(section => section.title === 'PISTOLETS')}/>
                    <MenuSection section={menu.find(section => section.title === 'CROQUE MONSIEUR')}/>
                    <MenuSection section={menu.find(section => section.title === 'CENTRAL KLASSIEKERS')}/>
                    <MenuSection section={menu.find(section => section.title === 'SOEPKES')}/>
                    <MenuSection section={menu.find(section => section.title === 'FIJNE VLEESWAREN')}/>
                    <MenuSection section={menu.find(section => section.title === 'KNABBELEN')}/>
                    <SuggestiesLarge/>
                    <Zaaltjes/>
                </Col>
            </Row>
            <FooterLogo/>

            <a className={styles.socialImageLink} href="https://www.facebook.com/leuvencentral" target="_blank" rel="noopener noreferrer">
                <img alt="" className={styles.socialImage} src={fbLogo}/>
            </a>
            <div className={styles.address}>Margarethaplein 3, 3000 Leuven</div>
        </Container>
    );
};

export default Menu;
