import React from 'react';
import styles from './Suggesties.module.css';
import classNames from 'classnames';

const Suggesties = ({className}) => {
    return (
        <div className={classNames(styles.suggesties, className)}>
            <span>Vraag naar de suzj<span style={{fontFamily: 'initial'}}>è</span>stes van 't moment<span style={{fontFamily: 'initial'}}>!</span></span>
        </div>
    );
};

export default Suggesties;
