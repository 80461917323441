import React from 'react';
import {BrowserRouter, Switch, Route} from "react-router-dom";
import Menu from "./menu/Menu";

function App() {
    return (
        <BrowserRouter>
            <Switch>

                <Route exact path="/">
                    <Menu/>
                </Route>

            </Switch>
        </BrowserRouter>
    );
}

export default App;
