import React from 'react';
import styles from './MenuHeader.module.css';

const MenuHeader = ({children}) => {
    return (
        <div className={styles.header}>
            <span className={styles.text}>{children}</span>
        </div>
    );
};

export default MenuHeader;
