import React from 'react';
import styles from './MenuItem.module.css';

const MenuItem = ({title, description, price, indent = 0}) => {
    return (
        <div className={styles.item}>
            <div className={styles.content} style={{marginLeft: indent}}>
                {title && <span className={styles.title}>{title}</span>}
                {description && <span className={styles.description}>{description}</span>}
            </div>
            <div className={styles.filler}/>
            <div className={styles.price}>{price}</div>
        </div>
    );
};

export default MenuItem;
