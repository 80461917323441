import React from 'react';
import styles from './Zaaltjes.module.css';

const Zaaltjes = () => {
    return (
        <div className={styles.zaaltjes}>
            LEUVEN CENTRAL heeft verschillende zaaltjes ter beschikking voor recepties, vergaderingen, drinks, feestjes, (kook)workshops... Info aan de toog!
        </div>
    );
};

export default Zaaltjes;
