import React from 'react';
import styles from './FooterLogo.module.css';
import {Link} from "react-router-dom";

const FooterLogo = () => {
    return (
        <Link className={styles.link} to="/">
            <img alt="" className={styles.image} src={require('../../images/leuvencentral-footer-white.png')}/>
        </Link>
    );
};

export default FooterLogo;
