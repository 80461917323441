import React from 'react';
import styles from './MenuSection.module.css';
import MenuHeader from "../MenuHeader/MenuHeader";
import MenuItem from "../MenuItem/MenuItem";

const MenuSection = ({section}) => {
    return (
        <div className={styles.menuSection}>
            <MenuHeader>{section.title}</MenuHeader>
            {section.items.map((item, i) => (
                <MenuItem key={i} {...item}/>
            ))}
        </div>
    );
};

export default MenuSection;
